import React, { lazy } from "react";

const Dashboard = lazy(() => import('views/dashboard'));
const Orders = lazy(() => import('views/order-management/orders'));
const OrderDetail = lazy(() => import('views/order-management/orders/Detail.js'));
const ActivateMembership = lazy(() => import('views/membership/ActivateMembership'));
const UpdateMembership = lazy(() => import('views/membership/UpdateMembership'));
const Profile = lazy(() => import('views/profile'));
const ExternalStore = lazy(() => import('views/external-store'));
const ListedProducts = lazy(() => import('views/listed-product'));
const Transactions = lazy(() => import('views/transactions'));
const Refunds = lazy(() => import('views/refunds'));
const Chat = lazy(() => import('views/chat'));

const DashboardRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/order-management/orders",
    component: <Orders />,
  },
  {
    path: "/order-management/order-detail/:id",
    component: <OrderDetail />,
  },
  {
    path: "/membership-plan/activate-membership",
    component: <ActivateMembership />,
  },
  {
    path: "/membership-plan/update-membership",
    component: <UpdateMembership />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/external-store",
    component: <ExternalStore />,
  },
  {
    path: "/listed-products",
    component: <ListedProducts />,
  },
  {
    path: "/transactions",
    component: <Transactions />,
  },
  {
    path: "/refunds",
    component: <Refunds />,
  },
  {
    path: "/chat",
    component: <Chat />,
  },
]

export default DashboardRoutes
